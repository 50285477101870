body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ImgItem {
  width: 100%;
  height: 230px;
  position: relative;
  border-color: #666;
  background: #fff;
}
.ImgItem-wrapper,
.ImgItem .banner-anim {
  height: 100%;
  background-color: black;
}
.ImgItem-wrapper,
.ImgItem h3 {
  font-family: inherit;
  font-weight: 500;
}
.ImgItem .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .ImgItem .bg {
    background-attachment: inherit;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.list-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.list-wrapper .ant-input-affix-wrapper {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.list-wrapper .ant-input-affix-wrapper:focus,
.list-wrapper .ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.list-wrapper .list {
  height: 100%;
  padding: 64px 24px;
}
.list-wrapper .list > .title-wrapper {
  margin: 0 auto 48px;
}
.list-wrapper .list .list-page {
  display: flex;
  width: 100%;
}
.list-wrapper .list .list-page-changeItem {
  width: 50px;
  line-height: 642px;
}
.list-wrapper .list .list-page-changeItem span:hover {
  color: #1890ff;
}
.list-wrapper .list-block {
  padding: 0 1%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.list-wrapper .list-block img {
  width: 100%;
}
.list-wrapper .list-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.list-wrapper .list-block.queue-anim-leaving {
  position: relative !important;
}
.list-wrapper .list-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.list-wrapper .list-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .list-wrapper {
    min-height: 880px;
  }
}

@charset "utf-8";
body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 97%;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
  padding-right: 10px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 280px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-nav_user {
  float: right;
  margin: 14px;
  cursor: pointer;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner1 {
  width: 100%;
  height: 350px;
  position: relative;
  border-color: #666;
  background: #fff;
}
.banner1-wrapper,
.banner1 .banner-anim {
  height: 100%;
}
.banner1 .queue-anim-leaving {
  position: relative !important;
}
.banner1 .banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.banner1 .bg0 {
  background: url("https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg") center;
}
.banner1 .bg1 {
  background: url("https://zos.alipayobjects.com/rmsportal/xHxWkcvaIcuAdQl.jpg") center;
}
.banner1 .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.banner1 .banner-user-elem .banner-user-title {
  font-size: 22px;
  top: 40%;
}
.banner1 .banner-user-elem .banner-user-text {
  top: 40%;
}
.banner1 .banner1-text-wrapper {
  display: block;
  position: relative;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 14px;
  color: #fff;
  width: 550px;
  text-align: center;
}
.banner1 .banner1-title {
  width: 350px;
  left: 30px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner1 .banner1-content {
  margin-bottom: 20px;
  word-wrap: break-word;
}
.banner1 .banner1-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  line-height: 36px;
  font-size: 16px;
  height: 36px;
}
.banner1 .banner1-button span {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: text-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner1 .banner1-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner1 .banner1-button:hover span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}
.banner1 .banner1-button.queue-anim-leaving {
  width: auto;
}
.banner1 .banner1-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 10;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
.banner-anim-thumb-default span {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 767px) {
  .banner1 .banner1-text-wrapper {
    width: 90%;
  }
  .banner1 .banner1-text-wrapper .banner1-title {
    width: 90%;
    left: 0;
  }
  .banner1 .bg {
    background-attachment: inherit;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 1%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.content1-wrapper {
  margin: 80px 0px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 400px;
  height: 300px;
  border: 1px solid #ccc;
  padding: 10px;
}
.content1-wrapper .content1-img span img {
  display: block;
  height: 100%;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content12-wrapper {
  margin: 80px 0px;
}
.content12-wrapper .content12 {
  height: 100%;
  padding: 0 24px;
}
.content12-wrapper .content12-img {
  height: 100%;
  -webkit-transform-origin: top;
          transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content12-wrapper .content12-img span {
  display: block;
  width: 250px;
}
.content12-wrapper .content12-img span img {
  display: block;
}
.content12-wrapper .content12-text {
  padding: 0 32px;
  height: 100%;
}
.content12-wrapper .content12-text .content12-content,
.content12-wrapper .content12-text .content12-title {
  position: relative !important;
}
.content12-wrapper .content12-text .content12-title {
  font-size: 32px;
  font-weight: normal;
  color: #1890ff;
  margin-top: 20px;
  line-height: 32px;
}
.content12-wrapper .content12-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content12-wrapper {
    height: 600px;
  }
  .content12-wrapper .content12-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content12-wrapper .content12-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content12-wrapper .content12-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content12-wrapper .content12-text .content12-content,
  .content12-wrapper .content12-text .content12-title {
    width: 100%;
    top: auto;
  }
  .content12-wrapper .content12-text .content12-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
/* CSS Document */
.pull-left {
  float: left!important;
}
.pull-right {
  float: right!important;
}
.text-center {
  text-align: center;
}
.glyphicon-th-large:before {
  content: "\E010";
}
li {
  list-style: none;
}
.text-right {
  text-align: right;
}
@font-face {
  font-family: 'iconfont';
  src: url('//at.alicdn.com/t/font_1432720974_1547027.eot');
  src: url('//at.alicdn.com/t/font_1432720974_1547027.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_1432720974_1547027.woff') format('woff'), url('//at.alicdn.com/t/font_1432720974_1547027.ttf') format('truetype'), url('//at.alicdn.com/t/font_1432720974_1547027.svg#iconfont') format('svg');
}
.fa {
  display: inline-block;
  font-family: "iconfont";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.fa-icon-qq:before {
  content: "\E604";
}
.fa-icon-weibo:before {
  content: "\E600";
}
.fa-icon-weixin:before {
  content: "\E605";
}
.fa-icon-zaixianzixun:before {
  content: "\E608";
}
body {
  font-family: "\5FAE\8F6F\96C5\9ED1";
}
.glyphicon {
  top: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-background {
  background: none !important;
}
.no-border {
  border: none !important;
}
.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w100 {
  width: 100% !important;
}
.h100 {
  height: 100% !important;
}
a {
  text-decoration: none !important;
  cursor: pointer !important;
}
.container-fluid {
  max-width: 1230px !important;
}
.tr {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.tr2 {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.main-pad-1 {
  padding: 50px 0 30px;
}
.main-pad-2 {
  padding: 30px 0;
}
.main-Background-1 {
  background: #f5f5f5;
}
header {
  position: relative;
  z-index: 9999;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.2);
}
header .logo {
  padding: 10px 0 0;
  line-height: 110px;
  font-size: 0;
}
header .menu {
  background: #b18d5b;
}
header .menu ul li a {
  display: block;
  color: #fff;
  line-height: 50px;
}
header .menu ul li .ul {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 55;
  width: 100%;
  background: linear-gradient(to bottom, rgba(177, 141, 91, 0.8) 0%, rgba(177, 141, 91, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ccb18d5b', endColorstr='#ccb18d5b', GradientType=0);
}
header .menu ul li .ul .li a {
  line-height: 40px;
}
.banner {
  position: relative;
  overflow: hidden;
}
.banner img {
  display: block;
  margin: 0 auto;
}
.banner .glyphicon {
  top: 50% !important;
}
.banner .carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.banner .carousel-fade .carousel-inner .active {
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.banner .carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}
.banner .carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.banner #myCarousel {
  position: relative;
}
.banner #myCarousel .carousel-indicators {
  margin-bottom: 0;
  bottom: 10px;
  z-index: 4;
}
.banner #myCarousel .carousel-indicators li {
  margin: 0 5px;
  width: 15px;
  height: 15px;
  background: #fff;
  border: none;
}
.banner #myCarousel .carousel-indicators li:hover {
  background: #8e7149;
}
.banner #myCarousel .carousel-indicators li.active {
  background: #8e7149;
}
.text-1 {
  padding: 15px 0;
  border-bottom: solid 1px #e5e5e5;
}
.text-1 .box {
  position: relative;
  padding: 0 240px 0 220px;
  min-height: 32px;
}
.text-1 .box .search {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1 ;
  padding: 0 30px 0 0;
  width: 240px;
  height: 32px;
  border: solid 1px #d4d4d4;
}
.text-1 .box .search input {
  padding: 0 10px;
  width: 100%;
  height: 30px;
  color: #666;
  background: none;
  border: none;
}
.text-1 .box ul {
  padding: 0 0 0 20px;
  height: 32px;
  overflow: hidden;
}
.text-1 .box ul li {
  margin: 0 30px 0 0;
  line-height: 32px;
}
.text-1 .box ul li a {
  font-size: 14px;
  color: #555;
}
.text-2 {
  margin: 0 0 20px;
}
.text-2 h3 {
  margin: 0 0 10px;
  font-size: 50px;
  color: #fff;
  line-height: 55px;
}
.text-2 p {
  margin: 0;
  padding: 0 10%;
  font-size: 20px;
  color: #fff;
  line-height: 34px;
}
.text-3 {
  margin: 0 0 25px;
  padding: 0 0 20px;
  border-bottom: solid 1px #ccc;
  height: 240px;
  overflow: hidden;
}
.text-3 .biaoti {
  font-size: 22px;
  color: #b18d5b;
  line-height: 42px;
}
.text-3 p {
  color: #666;
  line-height: 26px;
  overflow: hidden;
}
.text-3 a {
  display: block;
  color: #b18d5b;
  line-height: 24px;
}
.text-4 {
  margin: 0 0 20px;
}
.text-4 ul li {
  position: relative;
  padding: 0 90px 0 0;
  min-height: 36px;
  line-height: 35px;
  border-bottom: solid 1px #ccc;
}
.text-4 ul li a {
  font-size: 16px;
  color: #333;
}
.text-4 ul li span {
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  font-family: arial;
  font-size: 12px;
  color: #999;
}
.text-5 {
  margin: 0 0 20px;
  line-height: 26px;
}
.text-6 .imgbox {
  width: 100%;
  border: solid 1px #c4c2c2;
}
.text-6 section h1 {
  font-size: 16px;
  color: #666;
}
.text-6 section address {
  font-size: 12px;
  color: #666;
}
.text-7 {
  margin: 0 0 20px;
  color: #333;
  line-height: 26px;
}
.subnav .pcdnav li a {
  display: block;
  font-size: 14px;
  color: #8b8b8b;
  line-height: 35px;
  background-color: #ededed;
  position: relative;
}
.subnav .pcdnav li a span {
  position: absolute;
  left: 20px;
  top: 10px;
}
.subnav .pcdnav li a.active {
  color: #e70808;
}
.subnav .pcdnav li a:hover {
  color: #b18d5b;
}
.subnav .pcdnav li.active a {
  color: #b18d5b;
}
.title-1 {
  margin: 0 0 20px;
}
.title-1 a small {
  display: block;
  font-size: 24px;
  color: #999;
  line-height: 30px;
}
.title-1 a h5 {
  font-size: 0;
  line-height: 22px;
}
.title-1 a h5 span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 20px;
  color: #b18d5b;
  font-weight: bold;
}
.title-1 a h5 span:after,
.title-1 a h5 span:before {
  content: "";
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 100px;
  height: 1px;
  background: #ccc;
}
.title-1 a h5 span:after {
  left: -150px;
}
.title-1 a h5 span:before {
  right: -150px;
}
.title-2 {
  margin: 0 0 20px;
}
.title-2 ul {
  font-size: 0;
}
.title-2 ul li {
  display: inline-block;
  vertical-align: top;
  margin: 0 5px 10px;
}
.title-2 ul li a {
  display: block;
  padding: 0 10px;
  min-width: 120px;
  font-size: 14px;
  color: #b18d5b;
  line-height: 24px;
  border: solid 2px #b18d5b;
  border-radius: 14px;
}
.title-2 ul li.active a {
  color: #fff;
  background: #b18d5b;
}
.title-3 {
  margin: 0 0 20px;
  padding: 0 0 5px;
  border-bottom: solid 1px #ccc;
}
.title-3 .left a {
  font-size: 20px;
  color: #b18d5b;
  line-height: 24px;
}
.title-3 .left a span {
  margin: 0 0 0 10px;
  font-family: arial;
  color: #999;
}
.title-3 .right a {
  font-family: arial;
  color: #999;
  line-height: 24px;
}
.title-4 {
  margin: 0 0 20px;
  height: 34px;
  border-bottom: double 3px #d5d5d5;
}
.title-4 h5 {
  font-size: 16px;
  color: #777;
  line-height: 20px;
}
.title-4 p {
  color: #888;
  line-height: 20px;
}
.title-4 p a {
  color: #888;
}
.title-4 p strong {
  color: #b18d5b;
}
.title-5 {
  margin: 0 0 20px;
}
.title-5 h5 {
  margin: 0;
  font-size: 20px;
  color: #333;
  line-height: 22px;
}
.title-5 p {
  padding: 5px 0 0;
  margin: 10px auto 0;
  max-width: 480px;
  display: block;
  font-size: 14px;
  color: #999;
  line-height: 24px;
  border-top: solid 1px #ddd;
}
.more-1 {
  margin: 17px 0 20px;
  font-size: 0;
}
.more-1 a {
  display: inline-block;
  vertical-align: top;
  width: 170px;
  font-size: 13px;
  color: #333;
  line-height: 38px;
  background: #fff;
  border: solid 1px #b1b1b1;
  border-radius: 20px;
}
.pictrue-1-box .pictrue-1 {
  display: none;
}
.pictrue-1 {
  position: relative;
  margin: 0 -10px;
}
.pictrue-1 .item {
  padding: 0 10px;
}
.pictrue-1-list {
  margin: 0 0 20px;
}
.pictrue-1-list a {
  display: block;
}
.pictrue-1-list a .imgbox {
  overflow: hidden;
}
.pictrue-1-list a .imgbox span {
  display: block;
}
.pictrue-1-list a .imgbox span img {
  max-width: 100%;
}
.pictrue-1-list a h5 {
  padding: 0 15px;
  font-size: 14px;
  color: #666;
  line-height: 40px;
  background: #e5e5e5;
}
.pictrue-2 {
  margin: 0 0 20px;
  border: solid 12px #fff;
  box-shadow: 0 0 0 1px #e1e1e1;
  overflow: hidden;
}
.pictrue-2 a {
  display: block;
  height: 403px;
}
.pictrue-2 a img {
  max-width: 100%;
}
.pictrue-3 {
  position: relative;
  margin: 0 0 20px;
  padding: 0 34px;
}
.pictrue-3 .item {
  padding: 0 3px;
}
.pictrue-3 a {
  display: block;
  padding: 1px 0 0;
}
.pictrue-3 a .imgbox {
  height: 140px;
  line-height: 134px;
  font-size: 0;
  background: #fff;
  overflow: hidden;
  border: solid 3px #fff;
  box-shadow: 0 0 0 1px #e1e1e1;
  -webkit-box-shadow: 0 0 0 1px #e1e1e1;
  -moz-box-shadow: 0 0 0 1px #e1e1e1;
  -o-box-shadow: 0 0 0 1px #e1e1e1;
}
.pictrue-3 a .imgbox img {
  max-width: 100%;
  max-height: 100%;
}
.pictrue-3 a h5 {
  font-size: 14px;
  color: #666;
  line-height: 26px;
}
.pictrue-4 {
  margin: 0 0 10px;
}
.pictrue-4 .box_a {
  display: block;
  position: relative;
  padding: 0 0 0 220px;
  min-height: 160px;
}
.pictrue-4 .box_a .imgbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 220px;
  overflow: hidden;
}
.pictrue-4 .box_a .imgbox img {
  display: block;
  height: 160px;
}
.pictrue-4 .box_a .textbox {
  padding: 0 0 0 20px;
}
.pictrue-4 .box_a .textbox .biaoti {
  position: relative;
  padding: 0 90px 0 0;
  min-height: 32px;
  line-height: 32px;
}
.pictrue-4 .box_a .textbox .biaoti h5 {
  font-size: 16px;
  color: #333;
  line-height: 32px;
}
.pictrue-4 .box_a .textbox .biaoti span {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 80px;
  font-family: arial;
  font-size: 12px;
  color: #999;
}
.pictrue-4 .box_a .textbox p {
  margin: 0 0 10px;
  height: 78px;
  font-size: 14px;
  color: #666;
  line-height: 26px;
  overflow: hidden;
}
.pictrue-4 .box_a .textbox .more {
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 26px;
  background: #b18d5b;
  text-align: center;
}
.pictrue-5 {
  margin: 0 0 20px;
}
.pictrue-7-list {
  margin: 0 0 30px;
}
.pictrue-7-list .box {
  padding: 0 0 30px;
  border-bottom: solid 1px #d9d9d9;
}
.pictrue-7-list:last-child {
  margin: 0;
}
.pictrue-7-list:last-child .box {
  padding: 0;
  border-bottom: none;
}
.pictrue-7-list .box_a {
  display: block;
  position: relative;
  padding: 0 0 0 265px;
  min-height: 175px;
}
.pictrue-7-list .box_a .imgbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 265px;
  overflow: hidden;
  border: solid 1px #e5e5e5;
}
.pictrue-7-list .box_a .imgbox img {
  display: block;
  height: 173px;
}
.pictrue-7-list .box_a .textbox {
  padding: 0 0 0 20px;
}
.pictrue-7-list .box_a .textbox h5 {
  margin: 0 0 5px;
  font-size: 18px;
  color: #333;
  line-height: 22px;
}
.pictrue-7-list .box_a .textbox span {
  display: block;
  font-size: 14px;
  color: #999;
  line-height: 14px;
}
.pictrue-7-list .box_a .textbox p {
  margin: 5px 0 10px;
  color: #666;
  line-height: 22px;
  height: 88px;
  overflow: hidden;
}
.pictrue-7-list .box_a .textbox .more span {
  display: inline-block;
  vertical-align: top;
  width: 90px;
  font-size: 13px;
  color: #fff;
  line-height: 28px;
  background: #999;
}
.subnav-list.col-md-3 {
  width: 22%;
}
.subnav-list.col-md-9 {
  width: 78%;
}
.subnav ol {
  background: #b18d5b;
  border-bottom: solid 2px #fff;
}
.subnav ol b {
  margin: 0 10px;
  color: #fff;
  line-height: 35px;
}
.subnav ol h1 {
  font-size: 16px;
  color: #fff;
  line-height: 35px;
}
.subnav ol small {
  margin: 0 0 0 10px;
  color: #fff;
  line-height: 35px;
}
.subnav .contact-text {
  padding: 10px;
  font-size: 14px;
  color: #6a6a6a;
  line-height: 28px;
  background: #ededed;
}
.subnav .pcdnav {
  margin: 0 0 20px;
}
.subnav .pcdnav li {
  margin: 2px 0 0 0;
}
.subnav .pcdnav .xl {
  display: none;
}
.subnav .pcdnav .xlxl {
  margin: 2px 0 0 0;
}
.subnav .pcdnav .xlxl a {
  display: block;
  font-size: 12px;
  color: #999;
  line-height: 35px;
  background: #f4f4f4;
}
.subnav .pcdnav .xlxl a.active {
  color: #e70808;
  background: #e1e1e1;
}
footer .box .right {
  margin: 0 0 20px;
  width: 60%;
}
footer .box .right form .hang {
  position: relative;
  margin: 0 0 7px;
  padding: 0 0 0 58px;
  min-height: 40px;
}
footer .box .right form .hang span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  color: #fff;
  line-height: 40px;
}
footer .box .right form .hang input {
  padding: 0 10px;
  max-width: 100%;
  width: 320px;
  height: 40px;
  color: #fff;
  border: solid 1px #624f4e;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.22) 0%, rgba(0, 0, 0, 0.22) 1%, rgba(0, 0, 0, 0.22) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#38000000', endColorstr='#38000000', GradientType=1);
}
footer .box .right form .hang textarea {
  padding: 0 10px;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  height: 82px;
  line-height: 40px;
  color: #fff;
  background: none;
  border: solid 1px #624f4e;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.22) 0%, rgba(0, 0, 0, 0.22) 1%, rgba(0, 0, 0, 0.22) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#38000000', endColorstr='#38000000', GradientType=1);
}
footer .box .right form .hang .hangs input {
  padding: 0 10px;
  max-width: 100%;
  width: 172px;
  height: 40px;
  color: #fff;
  border: solid 1px #624f4e;
}
footer .box .right form .hang .hangs .yzm {
  margin: 0 0 0 5px;
  height: 40px;
  line-height: 40px;
  background: #fff;
}
footer .box .right form .hang button {
  margin: 9px 0 0;
  width: 174px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  background: #b18d5b;
  border: none;
}
footer .box .left {
  position: relative;
  margin: 0 0 20px;
  padding: 0 0 0 50px;
  width: 35%;
}
footer .box .left:after {
  filter: alpha(opacity=25);
  -moz-opacity: 0.25;
  -khtml-opacity: 0.25;
  opacity: 0.25;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 1px;
  height: 100%;
  background: #fff;
}
footer .box .left .tops .logo {
  padding: 0 20px 0 0;
  width: 220px;
  text-align: center;
  font-size: 0;
  line-height: 115px;
}
footer .box .left .tops .logo img {
  max-width: 100%;
}
footer .box .left .tops .ewm {
  position: relative;
  padding: 0 22px 0 0;
}
footer .box .left .tops .ewm img {
  width: 115px;
}
footer .box .left .tops .ewm h5 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  padding: 5px 0 0;
  width: 16px;
  color: #fff;
  line-height: 14px;
}
footer .box .left .textbox p {
  font-size: 13px;
  color: #fff;
  line-height: 30px;
}
footer .box .left .textbox p a {
  color: #fff;
}
footer .footer_bar {
  background: #b18d5b;
}
footer .footer_bar p {
  padding: 19px 0;
  color: #fff;
  line-height: 22px;
}
footer .footer_bar p a {
  color: #fff;
}
header .menu ul li:hover > a {
  background: #8e7149;
}
header .menu ul li.active > a {
  background: #8e7149;
}
header .menu ul li .ul .li:hover a {
  background: #b18d5b;
}
.title-3 .left a:hover {
  text-decoration: underline !important;
}
.title-3 .left a:hover span {
  color: #b18d5b;
}
.title-3 .right a:hover {
  color: #b18d5b;
  text-decoration: underline !important;
}
.title-4 p a:hover {
  color: #b18d5b;
}
.text-1 .box ul li a:hover {
  color: #b18d5b;
  text-decoration: underline !important;
}
.text-1 .box .search:hover {
  border: solid 1px #b18d5b;
}
.text-3 a:hover {
  text-decoration: underline !important;
}
.text-4 ul li:hover a {
  color: #b18d5b;
}
.more-1 a:hover {
  color: #fff;
  background: #b18d5b;
  border: solid 1px #b18d5b;
}
.pictrue-1-list a:hover .imgbox span {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
}
.pictrue-1-list a:hover h5 {
  color: #fff;
  background: #b18d5b;
}
.pictrue-2:hover a {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
}
.pictrue-3 a:hover .imgbox {
  box-shadow: 0 0 0 1px #b18d5b;
  -webkit-box-shadow: 0 0 0 1px #b18d5b;
  -moz-box-shadow: 0 0 0 1px #b18d5b;
  -o-box-shadow: 0 0 0 1px #b18d5b;
}
.pictrue-3 a:hover h5 {
  color: #b18d5b;
}
.pictrue-4 .box_a:hover .imgbox img {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
}
.pictrue-4 .box_a:hover .textbox h5 {
  color: #b18d5b;
}
.pictrue-4 .box_a:hover .textbox .more {
  background: #f39800;
}
.pictrue-7-list .box_a:hover .imgbox img {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
}
.pictrue-7-list .box_a:hover .textbox h5 {
  color: #b18d5b;
}
.pictrue-7-list .box_a:hover .textbox .more span {
  background: #b18d5b;
}
footer .box .right form .hang input:hover {
  border: solid 1px #fff;
}
footer .box .right form .hang textarea:hover {
  border: solid 1px #fff;
}
footer .box .right form .hang button:hover {
  background: #f39800;
}
footer .box .left .textbox p a:hover {
  color: #f39800;
  text-decoration: underline !important;
}
footer .footer_bar p a:hover {
  color: #f39800;
  text-decoration: underline !important;
}
.subnav .pcdnav .xlxl a:hover {
  color: #b18d5b;
  background: #e9e9e9;
}
@media (max-width: 1149px) {
  .text-1 .box ul li {
    margin: 0 15px 0 0;
  }
  footer .box .left {
    width: 45%;
  }
  footer .box .right {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .main-pad-1 {
    padding: 30px 0 10px;
  }
  header .logo {
    padding: 0;
    line-height: 80px;
  }
  header .logo img {
    width: 300px;
  }
  header .menu {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 55;
    width: 150px;
    background: linear-gradient(to bottom, rgba(177, 141, 91, 0.8) 0%, rgba(177, 141, 91, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ccb18d5b', endColorstr='#ccb18d5b', GradientType=0);
  }
  header .menu .container-fluid {
    padding: 0;
  }
  header .menu .container-fluid .row {
    margin: 0;
  }
  header .menu .container-fluid .row .col-xs-12 {
    padding: 0;
  }
  header .menu .box {
    padding: 0;
  }
  header .menu ul li {
    float: inherit !important;
    width: inherit;
    background: none;
  }
  header .menu ul li::after,
  header .menu ul li::before {
    display: none;
  }
  header .menu ul li a {
    font-size: 14px;
    line-height: 40px;
  }
  header .menu ul li .ul {
    display: none !important;
  }
  header .ydd_btn {
    margin: 20px 0 0 0;
    padding: 10px 7px;
    width: 45px;
    height: 40px;
    cursor: pointer;
  }
  header .ydd_btn span {
    display: block;
    width: 100%;
    height: 4px;
    background: #b18d5b;
  }
  header .ydd_btn span.span02 {
    margin: 4px 0;
  }
  header .ydd_btn.click span.span01 {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    top: 8px;
    margin-top: 0px;
  }
  header .ydd_btn.click span.span02 {
    margin: 4px 0;
  }
  header .ydd_btn.click span.span03 {
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    top: -8px;
    margin-top: 0px;
  }
  header .ydd_btn.click span.span02 {
    width: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  .text-2 h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .text-2 p {
    padding: 0 5%;
    font-size: 16px;
    line-height: 24px;
  }
  .text-3 p {
    height: inherit;
  }
  .pictrue-2 a {
    height: inherit;
  }
  .pictrue-7-list {
    margin: 0 0 20px;
  }
  .pictrue-7-list .box {
    padding: 0 0 20px;
  }
  .pictrue-7-list .box_a {
    padding: 0 0 0 120px;
    min-height: 110px;
  }
  .pictrue-7-list .box_a .imgbox {
    width: 120px;
  }
  .pictrue-7-list .box_a .imgbox img {
    height: 108px;
  }
  .pictrue-7-list .box_a .textbox {
    padding: 0 0 0 10px;
  }
  .pictrue-7-list .box_a .textbox h5 {
    margin: 0 0 5px;
    font-size: 14px;
    line-height: 18px;
  }
  .pictrue-7-list .box_a .textbox p {
    margin: 3px 0;
    font-size: 14px;
    line-height: 20px;
    height: 40px;
  }
  .pictrue-7-list .box_a .textbox .more span {
    width: 60px;
    font-size: 14px;
    line-height: 24px;
  }
  footer .box .left {
    float: inherit !important;
    padding: 0 0 20px;
    width: inherit;
    text-align: center;
  }
  footer .box .left:after {
    top: inherit;
    bottom: 0;
    width: 100%;
    height: 1px;
  }
  footer .box .left .tops {
    display: inline-block;
    vertical-align: top;
  }
  footer .box .left .textbox .tel {
    display: inline-block;
    vertical-align: top;
  }
  footer .box .left .textbox p br {
    display: none;
  }
  footer .box .right {
    float: inherit !important;
    width: inherit;
  }
  .subnav {
    margin: 0 0 20px;
  }
  .subnav .pcdnav {
    margin: 0;
  }
  .subnav ol h1 {
    position: relative;
    padding: 0 15px;
    width: 100%;
  }
  .subnav ol h1.active:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
  }
  .subnav ol h1 span {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .subnav-list.col-md-3 {
    width: 100%;
  }
  .subnav-list.col-md-9 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .main-pad-1 {
    padding: 20px 0 0;
  }
  .main-pad-2 {
    padding: 20px 0;
  }
  body {
    padding-bottom: 50px;
  }
  header .logo {
    line-height: 60px;
  }
  header .logo img {
    width: 220px;
  }
  header .menu {
    width: 120px;
  }
  header .menu ul li a {
    font-size: 14px;
    line-height: 34px;
  }
  header .ydd_btn {
    margin: 10px 0 0;
    padding: 10px;
  }
  .hot {
    height: 50px;
    background: #b18d5b;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    border-top: solid 2px #fff;
  }
  .hot span {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
  }
  .hot h6 {
    font-size: 12px;
    line-height: 20px;
  }
  .hot .row div a {
    display: block;
    color: #fff;
    padding: 7px 0;
  }
  .dbbox {
    position: fixed;
    left: 0;
    bottom: 70px;
    z-index: 99;
    width: 100%;
  }
  .dbbox .dbs {
    position: relative;
    padding: 0 15px;
    display: none;
  }
  .dbbox .dbs .neirong {
    padding: 10px;
    background: #fff;
    border-radius: 5px;
  }
  .dbbox .dbs.ewm-box {
    text-align: center;
  }
  .dbbox .dbs.ewm-box img {
    max-width: 140px;
  }
  .dbbox .dbs.fenxiang-box .bdshare-button-style1-32 .li {
    position: relative;
    padding: 6px 0;
    width: 20%;
    height: 44px;
  }
  .dbbox .dbs.fenxiang-box .bdshare-button-style1-32 a {
    position: absolute;
    left: 50%;
    top: 6px;
    margin: 0 0 0 -16px;
    float: inherit !important;
    display: block;
  }
  footer .footer_bar p {
    font-size: 14px;
  }
  footer .box .left .tops .logo {
    padding: 0;
    line-height: inherit;
  }
  .banner #myCarousel .carousel-indicators {
    bottom: 5px;
  }
  .banner #myCarousel .carousel-indicators li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
  }
  .text-1 .box {
    padding: 0;
  }
  .text-1 .box .tel {
    position: inherit;
    left: inherit;
    top: inherit;
    margin: 0 0 10px;
    padding: 0 0 0 24px;
    width: inherit;
    font-size: 16px;
    color: #666;
    background-size: 18px auto !important;
  }
  .text-1 .box .search {
    position: inherit;
    right: inherit;
    top: inherit;
    width: inherit;
  }
  .text-2 h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .text-2 p {
    font-size: 14px;
  }
  .text-3 .biaoti {
    font-size: 16px;
    line-height: 24px;
  }
  .text-3 p {
    font-size: 14px;
    line-height: 24px;
  }
  .text-4 ul li a {
    font-size: 14px;
  }
  .text-5 {
    font-size: 14px;
    line-height: 24px;
  }
  .title-1 a small {
    font-size: 16px;
    line-height: 24px;
  }
  .title-1 a h5 {
    line-height: 18px;
  }
  .title-1 a h5 span {
    font-size: 16px;
  }
  .title-1 a h5 span::after {
    left: -110px;
  }
  .title-1 a h5 span::before {
    right: -110px;
  }
  .title-2 ul li a {
    padding: 0 5px;
    font-size: 14px;
    min-width: 80px;
  }
  .title-3 .left a {
    font-size: 14px;
  }
  .title-3 .right a {
    font-size: 14px;
  }
  .title-5 h5 {
    font-size: 16px;
    line-height: 20px;
  }
  .more-1 {
    margin: 0 0 20px;
  }
  .more-1 a {
    font-size: 14px;
    line-height: 30px;
    width: 120px;
    border-radius: 15px;
  }
  .pictrue-1-list a h5 {
    font-size: 14px;
    line-height: 30px;
  }
  .pictrue-3 {
    padding: 0 28px;
  }
  .pictrue-3 a .imgbox {
    height: 80px;
    line-height: 74px;
  }
  .pictrue-4 .box_a {
    padding: 0 0 0 120px;
    min-height: 100px;
  }
  .pictrue-4 .box_a .imgbox {
    width: 120px;
  }
  .pictrue-4 .box_a .imgbox img {
    height: 100px;
  }
  .pictrue-4 .box_a .textbox {
    padding: 0 0 0 10px;
  }
  .pictrue-4 .box_a .textbox .biaoti {
    padding: 0 70px 0 0;
    line-height: 24px;
    min-height: 24px;
  }
  .pictrue-4 .box_a .textbox .biaoti h5 {
    font-size: 14px;
    line-height: 24px;
  }
  .pictrue-4 .box_a .textbox p {
    margin: 0 0 5px;
    height: 48px;
    line-height: 24px;
  }
  .pictrue-4 .box_a .textbox .more {
    line-height: 22px;
  }
}
@media (max-width: 479px) {
  footer .box .left .tops {
    display: block;
  }
  footer .box .left .tops .logo {
    float: inherit !important;
    margin: 0 0 10px;
    width: inherit;
  }
  footer .box .left .tops .ewm {
    float: inherit !important;
    padding: 0;
    width: inherit;
  }
  footer .box .left .tops .ewm h5 {
    position: inherit;
    right: inherit;
    top: inherit;
    width: inherit;
  }
  footer .box .left .textbox .tel {
    padding: 0 0 0 25px;
    font-size: 18px;
    line-height: 20px;
    background-size: auto 20px !important;
  }
  footer .box .left .textbox p {
    font-size: 14px;
    line-height: 24px;
  }
  footer .box .right form .hang {
    padding: 0 0 0 48px;
  }
  footer .box .right form .hang span {
    font-size: 14px;
  }
  footer .box .right form .hang input {
    font-size: 14px;
    width: 100% !important;
  }
  footer .box .right form .hang textarea {
    font-size: 14px;
  }
  footer .box .right form .hang .hangs .yzm {
    margin: 5px 0 0;
  }
  footer .box .right form .hang button {
    width: 100%;
  }
}
.content5-wrapper {
  background-color: #fafafa;
  min-height: 720px;
}
.content5-wrapper .content5 > p {
  text-align: center;
}
.content5-wrapper .content5-img-wrapper {
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content5-wrapper .content5-img-wrapper .block {
  margin-bottom: 24px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content {
  display: block;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: none;
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content > span {
  width: 100%;
  height: 160px;
  display: block;
  background: #e9e9e9;
  padding: 5%;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content p {
  width: 100%;
  line-height: 30px;
}
.content5-wrapper .content5-img-wrapper .block .content5-block-content:hover p {
  bottom: 0;
}
@media screen and (max-width: 767px) {
  .content5-wrapper {
    height: 2000px;
    overflow: hidden;
  }
  .content5-wrapper .content5 ul li {
    display: block;
    width: 100%;
    padding: 2%;
  }
  .content5-wrapper .content5 ul li span {
    height: 168px;
  }
  .content5-wrapper .content5 ul li p {
    position: relative;
    bottom: 0;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.content7-wrapper {
  min-height: 720px;
}
.content7-wrapper .content7 > h1,
.content7-wrapper .content7 > p {
  text-align: center;
}
.content7-wrapper .content7-tag i {
  width: 12px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.content7-wrapper .content7-tag-name {
  display: inline-block;
}
.content7-wrapper .content7 .ant-tabs-bar {
  text-align: center;
}
.content7-wrapper .content7 .ant-tabs .ant-tabs-nav {
  float: none;
  text-align: center;
}
.content7-wrapper .content7-tabs-wrapper {
  position: relative;
  margin-top: 24px;
}
.content7-wrapper .content7-content {
  display: flex;
  align-items: center;
}
.content7-wrapper .content7-text {
  padding: 24px 48px;
}
.content7-wrapper .content7-img {
  padding: 24px 48px;
}
.content7-wrapper .content7 .ant-tabs-tabpane {
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .content7-wrapper {
    min-height: 980px;
    overflow: hidden;
  }
  .content7-wrapper .content7 {
    max-width: 100%;
  }
  .content7-wrapper .content7-content {
    display: block;
  }
  .content7-wrapper .content7-text,
  .content7-wrapper .content7-img {
    text-align: left;
    padding: 0;
  }
  .content7-wrapper .content7-img {
    margin-top: 32px;
  }
  .content7-wrapper .content7 .ant-tabs-bar {
    width: auto;
  }
  .content7-wrapper .content7 .ant-tabs-bar .ant-tabs-nav {
    float: left;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 220px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
#Content0_0 .home-page > div > .k9h76g7v2q-editor_css {
  justify-content: center;
}
#Footer1_0 .ant-col > div > .k9h7irl6jgj-editor_css {
  display: flex;
  justify-content: center;
}
#Footer1_0 .ant-col > div > .k9h7jbsf8ap-editor_css {
  display: flex;
  justify-content: center;
}
#Footer1_0 .ant-row > .ant-col > .k9h7k134cu-editor_css {
  display: flex;
  justify-content: center;
}

.imageCode {
  -webkit-user-select: none;
          user-select: none;
}
.imageContainer {
  position: relative;
  background-color: #ddd;
}
.canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.reloadContainer {
  margin: 5px 0;
}
.reloadWrapper {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.reloadIco {
  width: 25px;
  height: 20px;
  background: center / cover no-repeat;
}
.reloadTips {
  font-size: 14px;
  color: #666;
}
.sliderWrpper {
  position: relative;
  margin: 10px 0;
}
.sliderBar {
  font-size: 14px;
  text-align: center;
  color: #999;
  background-color: #ddd;
}
.sliderButton {
  position: absolute;
  top: 50%;
  left: 0;
  width: 50px;
  height: 35px;
  border-radius: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  background: transparent;
  background-size: 100% 100%;
  box-shadow: 0 2px 10px 0 #333;
}
/* 提示信息 */
.tipsContainer,
.tipsContainerActive {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  padding: 10px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: all 0.25s;
  background: #fff;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
}
.tipsContainerActive {
  visibility: visible;
  opacity: 1;
}
.tipsIco {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: center / cover no-repeat;
}
.tipsText {
  color: #666;
}

.login_wrap {
  overflow: auto;
  background-color: #ffffff;
  border-radius: 6px 6px 0 0;
  padding: 42px 24px 50px;
  color: rgba(0, 0, 0, 0.88);
  text-align: center;
  background-image: url(../../static/media/TVYTbAXWheQpRcWDaDMu.5825f033.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}
.login_title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  line-height: 44px;
  margin-top: 100px;
}
.login_title > h1 {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 33px;
}
.login-logo {
  width: 44px;
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}
.login-logo > img {
  width: 44px;
  height: 44px;
}
.login-form {
  max-width: 300px;
  margin: auto;
  margin-top: 50px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.register_wrap {
  overflow: auto;
  background-color: #ffffff;
  border-radius: 6px 6px 0 0;
  padding: 42px 24px 50px;
  color: rgba(0, 0, 0, 0.88);
  text-align: center;
  background-image: url(../../static/media/TVYTbAXWheQpRcWDaDMu.5825f033.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
}
.register_wrap .ant-input-affix-wrapper > .ant-input {
  font-size: 18px;
}
.register-form {
  max-width: 800px !important;
  margin: auto;
  padding-right: 200px;
}
.register-form-forgot {
  float: right;
}
.ant-col-rtl .register-form-forgot {
  float: left;
}
.register-form-button {
  width: 100%;
}

.notice_search {
  padding-bottom: 0px !important;
  margin: 10px -6px;
}
.notice_search > div:first-child {
  position: relative;
  left: 26px;
  z-index: 99;
}
.notice_wrap {
  padding: 0px 24px;
  max-width: 97%;
  margin: auto;
  margin-top: 50px;
}
.notice_wrap .ant-input-affix-wrapper {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.notice_wrap .ant-input-affix-wrapper:focus,
.notice_wrap .ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.notice_wrap_list .ant-list-items .box_notic {
  padding: 0 0 30px;
  border-bottom: solid 1px #d9d9d9;
  margin-bottom: 30px;
}
.notice_wrap_list .ant-list-items .box_notic:last-child {
  padding: 0;
  border-bottom: none;
  margin-bottom: 30px;
}
.notice_item_content_desc {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
  margin: 10px 0 15px;
  color: #666;
  line-height: 22px;
}
.notice_wrap_list {
  width: 100%;
  padding: 0px 30px;
  padding-bottom: 20px;
}
.notice_item {
  border: 1px solid #cccccc;
  border-radius: 4px;
}

@charset "utf-8";
/* CSS Document */
.pull-left {
  float: left!important;
}
.pull-right {
  float: right!important;
}
.text-center {
  text-align: center;
}
.glyphicon-th-large:before {
  content: "\E010";
}
li {
  list-style: none;
}
.text-right {
  text-align: right;
}
@font-face {
  font-family: 'iconfont';
  src: url('//at.alicdn.com/t/font_1432720974_1547027.eot');
  src: url('//at.alicdn.com/t/font_1432720974_1547027.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_1432720974_1547027.woff') format('woff'), url('//at.alicdn.com/t/font_1432720974_1547027.ttf') format('truetype'), url('//at.alicdn.com/t/font_1432720974_1547027.svg#iconfont') format('svg');
}
.fa {
  display: inline-block;
  font-family: "iconfont";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.fa-icon-qq:before {
  content: "\E604";
}
.fa-icon-weibo:before {
  content: "\E600";
}
.fa-icon-weixin:before {
  content: "\E605";
}
.fa-icon-zaixianzixun:before {
  content: "\E608";
}
body {
  font-family: "\5FAE\8F6F\96C5\9ED1";
}
.glyphicon {
  top: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-background {
  background: none !important;
}
.no-border {
  border: none !important;
}
.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.w100 {
  width: 100% !important;
}
.h100 {
  height: 100% !important;
}
a {
  text-decoration: none !important;
  cursor: pointer !important;
}
.container-fluid {
  max-width: 1230px !important;
}
.tr {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.tr2 {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.main-pad-1 {
  padding: 50px 0 30px;
}
.main-pad-2 {
  padding: 30px 0;
}
.main-Background-1 {
  background: #f5f5f5;
}
header {
  position: relative;
  z-index: 9999;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.2);
}
header .logo {
  padding: 10px 0 0;
  line-height: 110px;
  font-size: 0;
}
header .menu {
  background: #b18d5b;
}
header .menu ul li a {
  display: block;
  color: #fff;
  line-height: 50px;
}
header .menu ul li .ul {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 55;
  width: 100%;
  background: linear-gradient(to bottom, rgba(177, 141, 91, 0.8) 0%, rgba(177, 141, 91, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ccb18d5b', endColorstr='#ccb18d5b', GradientType=0);
}
header .menu ul li .ul .li a {
  line-height: 40px;
}
.banner {
  position: relative;
  overflow: hidden;
}
.banner img {
  display: block;
  margin: 0 auto;
}
.banner .glyphicon {
  top: 50% !important;
}
.banner .carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.banner .carousel-fade .carousel-inner .active {
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.banner .carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}
.banner .carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.banner #myCarousel {
  position: relative;
}
.banner #myCarousel .carousel-indicators {
  margin-bottom: 0;
  bottom: 10px;
  z-index: 4;
}
.banner #myCarousel .carousel-indicators li {
  margin: 0 5px;
  width: 15px;
  height: 15px;
  background: #fff;
  border: none;
}
.banner #myCarousel .carousel-indicators li:hover {
  background: #8e7149;
}
.banner #myCarousel .carousel-indicators li.active {
  background: #8e7149;
}
.text-1 {
  padding: 15px 0;
  border-bottom: solid 1px #e5e5e5;
}
.text-1 .box {
  position: relative;
  padding: 0 240px 0 220px;
  min-height: 32px;
}
.text-1 .box .search {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1 ;
  padding: 0 30px 0 0;
  width: 240px;
  height: 32px;
  border: solid 1px #d4d4d4;
}
.text-1 .box .search input {
  padding: 0 10px;
  width: 100%;
  height: 30px;
  color: #666;
  background: none;
  border: none;
}
.text-1 .box ul {
  padding: 0 0 0 20px;
  height: 32px;
  overflow: hidden;
}
.text-1 .box ul li {
  margin: 0 30px 0 0;
  line-height: 32px;
}
.text-1 .box ul li a {
  font-size: 14px;
  color: #555;
}
.text-2 {
  margin: 0 0 20px;
}
.text-2 h3 {
  margin: 0 0 10px;
  font-size: 50px;
  color: #fff;
  line-height: 55px;
}
.text-2 p {
  margin: 0;
  padding: 0 10%;
  font-size: 20px;
  color: #fff;
  line-height: 34px;
}
.text-3 {
  margin: 0 0 25px;
  padding: 0 0 20px;
  border-bottom: solid 1px #ccc;
  height: 240px;
  overflow: hidden;
}
.text-3 .biaoti {
  font-size: 22px;
  color: #b18d5b;
  line-height: 42px;
}
.text-3 p {
  color: #666;
  line-height: 26px;
  overflow: hidden;
}
.text-3 a {
  display: block;
  color: #b18d5b;
  line-height: 24px;
}
.text-4 {
  margin: 0 0 20px;
}
.text-4 ul li {
  position: relative;
  padding: 0 90px 0 0;
  min-height: 36px;
  line-height: 35px;
  border-bottom: solid 1px #ccc;
}
.text-4 ul li a {
  font-size: 16px;
  color: #333;
}
.text-4 ul li span {
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  font-family: arial;
  font-size: 12px;
  color: #999;
}
.text-5 {
  margin: 0 0 20px;
  line-height: 26px;
}
.text-6 .imgbox {
  width: 100%;
  border: solid 1px #c4c2c2;
}
.text-6 section h1 {
  font-size: 16px;
  color: #666;
}
.text-6 section address {
  font-size: 12px;
  color: #666;
}
.text-7 {
  margin: 0 0 20px;
  color: #333;
  line-height: 26px;
}
.subnav .pcdnav li a {
  display: block;
  font-size: 14px;
  color: #8b8b8b;
  line-height: 35px;
  background-color: #ededed;
  position: relative;
}
.subnav .pcdnav li a span {
  position: absolute;
  left: 20px;
  top: 10px;
}
.subnav .pcdnav li a.active {
  color: #e70808;
}
.subnav .pcdnav li a:hover {
  color: #b18d5b;
}
.subnav .pcdnav li.active a {
  color: #b18d5b;
}
.title-1 {
  margin: 0 0 20px;
}
.title-1 a small {
  display: block;
  font-size: 24px;
  color: #999;
  line-height: 30px;
}
.title-1 a h5 {
  font-size: 0;
  line-height: 22px;
}
.title-1 a h5 span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 20px;
  color: #b18d5b;
  font-weight: bold;
}
.title-1 a h5 span:after,
.title-1 a h5 span:before {
  content: "";
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 100px;
  height: 1px;
  background: #ccc;
}
.title-1 a h5 span:after {
  left: -150px;
}
.title-1 a h5 span:before {
  right: -150px;
}
.title-2 {
  margin: 0 0 20px;
}
.title-2 ul {
  font-size: 0;
}
.title-2 ul li {
  display: inline-block;
  vertical-align: top;
  margin: 0 5px 10px;
}
.title-2 ul li a {
  display: block;
  padding: 0 10px;
  min-width: 120px;
  font-size: 14px;
  color: #b18d5b;
  line-height: 24px;
  border: solid 2px #b18d5b;
  border-radius: 14px;
}
.title-2 ul li.active a {
  color: #fff;
  background: #b18d5b;
}
.title-3 {
  margin: 0 0 20px;
  padding: 0 0 5px;
  border-bottom: solid 1px #ccc;
}
.title-3 .left a {
  font-size: 20px;
  color: #b18d5b;
  line-height: 24px;
}
.title-3 .left a span {
  margin: 0 0 0 10px;
  font-family: arial;
  color: #999;
}
.title-3 .right a {
  font-family: arial;
  color: #999;
  line-height: 24px;
}
.title-4 {
  margin: 0 0 20px;
  height: 34px;
  border-bottom: double 3px #d5d5d5;
}
.title-4 h5 {
  font-size: 16px;
  color: #777;
  line-height: 20px;
}
.title-4 p {
  color: #888;
  line-height: 20px;
}
.title-4 p a {
  color: #888;
}
.title-4 p strong {
  color: #b18d5b;
}
.title-5 {
  margin: 0 0 20px;
}
.title-5 h5 {
  margin: 0;
  font-size: 20px;
  color: #333;
  line-height: 22px;
}
.title-5 p {
  padding: 5px 0 0;
  margin: 10px auto 0;
  max-width: 480px;
  display: block;
  font-size: 14px;
  color: #999;
  line-height: 24px;
  border-top: solid 1px #ddd;
}
.more-1 {
  margin: 17px 0 20px;
  font-size: 0;
}
.more-1 a {
  display: inline-block;
  vertical-align: top;
  width: 170px;
  font-size: 13px;
  color: #333;
  line-height: 38px;
  background: #fff;
  border: solid 1px #b1b1b1;
  border-radius: 20px;
}
.pictrue-1-box .pictrue-1 {
  display: none;
}
.pictrue-1 {
  position: relative;
  margin: 0 -10px;
}
.pictrue-1 .item {
  padding: 0 10px;
}
.pictrue-1-list {
  margin: 0 0 20px;
}
.pictrue-1-list a {
  display: block;
}
.pictrue-1-list a .imgbox {
  overflow: hidden;
}
.pictrue-1-list a .imgbox span {
  display: block;
}
.pictrue-1-list a .imgbox span img {
  max-width: 100%;
}
.pictrue-1-list a h5 {
  padding: 0 15px;
  font-size: 14px;
  color: #666;
  line-height: 40px;
  background: #e5e5e5;
}
.pictrue-2 {
  margin: 0 0 20px;
  border: solid 12px #fff;
  box-shadow: 0 0 0 1px #e1e1e1;
  overflow: hidden;
}
.pictrue-2 a {
  display: block;
  height: 403px;
}
.pictrue-2 a img {
  max-width: 100%;
}
.pictrue-3 {
  position: relative;
  margin: 0 0 20px;
  padding: 0 34px;
}
.pictrue-3 .item {
  padding: 0 3px;
}
.pictrue-3 a {
  display: block;
  padding: 1px 0 0;
}
.pictrue-3 a .imgbox {
  height: 140px;
  line-height: 134px;
  font-size: 0;
  background: #fff;
  overflow: hidden;
  border: solid 3px #fff;
  box-shadow: 0 0 0 1px #e1e1e1;
  -webkit-box-shadow: 0 0 0 1px #e1e1e1;
  -moz-box-shadow: 0 0 0 1px #e1e1e1;
  -o-box-shadow: 0 0 0 1px #e1e1e1;
}
.pictrue-3 a .imgbox img {
  max-width: 100%;
  max-height: 100%;
}
.pictrue-3 a h5 {
  font-size: 14px;
  color: #666;
  line-height: 26px;
}
.pictrue-4 {
  margin: 0 0 10px;
}
.pictrue-4 .box_a {
  display: block;
  position: relative;
  padding: 0 0 0 220px;
  min-height: 160px;
}
.pictrue-4 .box_a .imgbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 220px;
  overflow: hidden;
}
.pictrue-4 .box_a .imgbox img {
  display: block;
  height: 160px;
}
.pictrue-4 .box_a .textbox {
  padding: 0 0 0 20px;
}
.pictrue-4 .box_a .textbox .biaoti {
  position: relative;
  padding: 0 90px 0 0;
  min-height: 32px;
  line-height: 32px;
}
.pictrue-4 .box_a .textbox .biaoti h5 {
  font-size: 16px;
  color: #333;
  line-height: 32px;
}
.pictrue-4 .box_a .textbox .biaoti span {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 80px;
  font-family: arial;
  font-size: 12px;
  color: #999;
}
.pictrue-4 .box_a .textbox p {
  margin: 0 0 10px;
  height: 78px;
  font-size: 14px;
  color: #666;
  line-height: 26px;
  overflow: hidden;
}
.pictrue-4 .box_a .textbox .more {
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 26px;
  background: #b18d5b;
  text-align: center;
}
.pictrue-5 {
  margin: 0 0 20px;
}
.pictrue-7-list {
  margin: 0 0 30px;
}
.pictrue-7-list .box {
  padding: 0 0 30px;
  border-bottom: solid 1px #d9d9d9;
}
.pictrue-7-list:last-child {
  margin: 0;
}
.pictrue-7-list:last-child .box {
  padding: 0;
  border-bottom: none;
}
.pictrue-7-list .box_a {
  display: block;
  position: relative;
  padding: 0 0 0 265px;
  min-height: 175px;
}
.pictrue-7-list .box_a .imgbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 265px;
  overflow: hidden;
  border: solid 1px #e5e5e5;
}
.pictrue-7-list .box_a .imgbox img {
  display: block;
  height: 173px;
}
.pictrue-7-list .box_a .textbox {
  padding: 0 0 0 20px;
}
.pictrue-7-list .box_a .textbox h5 {
  margin: 0 0 5px;
  font-size: 18px;
  color: #333;
  line-height: 22px;
}
.pictrue-7-list .box_a .textbox span {
  display: block;
  font-size: 14px;
  color: #999;
  line-height: 14px;
}
.pictrue-7-list .box_a .textbox p {
  margin: 5px 0 10px;
  color: #666;
  line-height: 22px;
  height: 88px;
  overflow: hidden;
}
.pictrue-7-list .box_a .textbox .more span {
  display: inline-block;
  vertical-align: top;
  width: 90px;
  font-size: 13px;
  color: #fff;
  line-height: 28px;
  background: #999;
}
.subnav-list.col-md-3 {
  width: 22%;
}
.subnav-list.col-md-9 {
  width: 78%;
}
.subnav ol {
  background: #b18d5b;
  border-bottom: solid 2px #fff;
}
.subnav ol b {
  margin: 0 10px;
  color: #fff;
  line-height: 35px;
}
.subnav ol h1 {
  font-size: 16px;
  color: #fff;
  line-height: 35px;
}
.subnav ol small {
  margin: 0 0 0 10px;
  color: #fff;
  line-height: 35px;
}
.subnav .contact-text {
  padding: 10px;
  font-size: 14px;
  color: #6a6a6a;
  line-height: 28px;
  background: #ededed;
}
.subnav .pcdnav {
  margin: 0 0 20px;
}
.subnav .pcdnav li {
  margin: 2px 0 0 0;
}
.subnav .pcdnav .xl {
  display: none;
}
.subnav .pcdnav .xlxl {
  margin: 2px 0 0 0;
}
.subnav .pcdnav .xlxl a {
  display: block;
  font-size: 12px;
  color: #999;
  line-height: 35px;
  background: #f4f4f4;
}
.subnav .pcdnav .xlxl a.active {
  color: #e70808;
  background: #e1e1e1;
}
footer .box .right {
  margin: 0 0 20px;
  width: 60%;
}
footer .box .right form .hang {
  position: relative;
  margin: 0 0 7px;
  padding: 0 0 0 58px;
  min-height: 40px;
}
footer .box .right form .hang span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  color: #fff;
  line-height: 40px;
}
footer .box .right form .hang input {
  padding: 0 10px;
  max-width: 100%;
  width: 320px;
  height: 40px;
  color: #fff;
  border: solid 1px #624f4e;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.22) 0%, rgba(0, 0, 0, 0.22) 1%, rgba(0, 0, 0, 0.22) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#38000000', endColorstr='#38000000', GradientType=1);
}
footer .box .right form .hang textarea {
  padding: 0 10px;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  height: 82px;
  line-height: 40px;
  color: #fff;
  background: none;
  border: solid 1px #624f4e;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.22) 0%, rgba(0, 0, 0, 0.22) 1%, rgba(0, 0, 0, 0.22) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#38000000', endColorstr='#38000000', GradientType=1);
}
footer .box .right form .hang .hangs input {
  padding: 0 10px;
  max-width: 100%;
  width: 172px;
  height: 40px;
  color: #fff;
  border: solid 1px #624f4e;
}
footer .box .right form .hang .hangs .yzm {
  margin: 0 0 0 5px;
  height: 40px;
  line-height: 40px;
  background: #fff;
}
footer .box .right form .hang button {
  margin: 9px 0 0;
  width: 174px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  background: #b18d5b;
  border: none;
}
footer .box .left {
  position: relative;
  margin: 0 0 20px;
  padding: 0 0 0 50px;
  width: 35%;
}
footer .box .left:after {
  filter: alpha(opacity=25);
  -moz-opacity: 0.25;
  -khtml-opacity: 0.25;
  opacity: 0.25;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 1px;
  height: 100%;
  background: #fff;
}
footer .box .left .tops .logo {
  padding: 0 20px 0 0;
  width: 220px;
  text-align: center;
  font-size: 0;
  line-height: 115px;
}
footer .box .left .tops .logo img {
  max-width: 100%;
}
footer .box .left .tops .ewm {
  position: relative;
  padding: 0 22px 0 0;
}
footer .box .left .tops .ewm img {
  width: 115px;
}
footer .box .left .tops .ewm h5 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  padding: 5px 0 0;
  width: 16px;
  color: #fff;
  line-height: 14px;
}
footer .box .left .textbox p {
  font-size: 13px;
  color: #fff;
  line-height: 30px;
}
footer .box .left .textbox p a {
  color: #fff;
}
footer .footer_bar {
  background: #b18d5b;
}
footer .footer_bar p {
  padding: 19px 0;
  color: #fff;
  line-height: 22px;
}
footer .footer_bar p a {
  color: #fff;
}
header .menu ul li:hover > a {
  background: #8e7149;
}
header .menu ul li.active > a {
  background: #8e7149;
}
header .menu ul li .ul .li:hover a {
  background: #b18d5b;
}
.title-3 .left a:hover {
  text-decoration: underline !important;
}
.title-3 .left a:hover span {
  color: #b18d5b;
}
.title-3 .right a:hover {
  color: #b18d5b;
  text-decoration: underline !important;
}
.title-4 p a:hover {
  color: #b18d5b;
}
.text-1 .box ul li a:hover {
  color: #b18d5b;
  text-decoration: underline !important;
}
.text-1 .box .search:hover {
  border: solid 1px #b18d5b;
}
.text-3 a:hover {
  text-decoration: underline !important;
}
.text-4 ul li:hover a {
  color: #b18d5b;
}
.more-1 a:hover {
  color: #fff;
  background: #b18d5b;
  border: solid 1px #b18d5b;
}
.pictrue-1-list a:hover .imgbox span {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
}
.pictrue-1-list a:hover h5 {
  color: #fff;
  background: #b18d5b;
}
.pictrue-2:hover a {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
}
.pictrue-3 a:hover .imgbox {
  box-shadow: 0 0 0 1px #b18d5b;
  -webkit-box-shadow: 0 0 0 1px #b18d5b;
  -moz-box-shadow: 0 0 0 1px #b18d5b;
  -o-box-shadow: 0 0 0 1px #b18d5b;
}
.pictrue-3 a:hover h5 {
  color: #b18d5b;
}
.pictrue-4 .box_a:hover .imgbox img {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
}
.pictrue-4 .box_a:hover .textbox h5 {
  color: #b18d5b;
}
.pictrue-4 .box_a:hover .textbox .more {
  background: #f39800;
}
.pictrue-7-list .box_a:hover .imgbox img {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
}
.pictrue-7-list .box_a:hover .textbox h5 {
  color: #b18d5b;
}
.pictrue-7-list .box_a:hover .textbox .more span {
  background: #b18d5b;
}
footer .box .right form .hang input:hover {
  border: solid 1px #fff;
}
footer .box .right form .hang textarea:hover {
  border: solid 1px #fff;
}
footer .box .right form .hang button:hover {
  background: #f39800;
}
footer .box .left .textbox p a:hover {
  color: #f39800;
  text-decoration: underline !important;
}
footer .footer_bar p a:hover {
  color: #f39800;
  text-decoration: underline !important;
}
.subnav .pcdnav .xlxl a:hover {
  color: #b18d5b;
  background: #e9e9e9;
}
@media (max-width: 1149px) {
  .text-1 .box ul li {
    margin: 0 15px 0 0;
  }
  footer .box .left {
    width: 45%;
  }
  footer .box .right {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .main-pad-1 {
    padding: 30px 0 10px;
  }
  header .logo {
    padding: 0;
    line-height: 80px;
  }
  header .logo img {
    width: 300px;
  }
  header .menu {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 55;
    width: 150px;
    background: linear-gradient(to bottom, rgba(177, 141, 91, 0.8) 0%, rgba(177, 141, 91, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ccb18d5b', endColorstr='#ccb18d5b', GradientType=0);
  }
  header .menu .container-fluid {
    padding: 0;
  }
  header .menu .container-fluid .row {
    margin: 0;
  }
  header .menu .container-fluid .row .col-xs-12 {
    padding: 0;
  }
  header .menu .box {
    padding: 0;
  }
  header .menu ul li {
    float: inherit !important;
    width: inherit;
    background: none;
  }
  header .menu ul li::after,
  header .menu ul li::before {
    display: none;
  }
  header .menu ul li a {
    font-size: 14px;
    line-height: 40px;
  }
  header .menu ul li .ul {
    display: none !important;
  }
  header .ydd_btn {
    margin: 20px 0 0 0;
    padding: 10px 7px;
    width: 45px;
    height: 40px;
    cursor: pointer;
  }
  header .ydd_btn span {
    display: block;
    width: 100%;
    height: 4px;
    background: #b18d5b;
  }
  header .ydd_btn span.span02 {
    margin: 4px 0;
  }
  header .ydd_btn.click span.span01 {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    top: 8px;
    margin-top: 0px;
  }
  header .ydd_btn.click span.span02 {
    margin: 4px 0;
  }
  header .ydd_btn.click span.span03 {
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    top: -8px;
    margin-top: 0px;
  }
  header .ydd_btn.click span.span02 {
    width: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
  }
  .text-2 h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .text-2 p {
    padding: 0 5%;
    font-size: 16px;
    line-height: 24px;
  }
  .text-3 p {
    height: inherit;
  }
  .pictrue-2 a {
    height: inherit;
  }
  .pictrue-7-list {
    margin: 0 0 20px;
  }
  .pictrue-7-list .box {
    padding: 0 0 20px;
  }
  .pictrue-7-list .box_a {
    padding: 0 0 0 120px;
    min-height: 110px;
  }
  .pictrue-7-list .box_a .imgbox {
    width: 120px;
  }
  .pictrue-7-list .box_a .imgbox img {
    height: 108px;
  }
  .pictrue-7-list .box_a .textbox {
    padding: 0 0 0 10px;
  }
  .pictrue-7-list .box_a .textbox h5 {
    margin: 0 0 5px;
    font-size: 14px;
    line-height: 18px;
  }
  .pictrue-7-list .box_a .textbox p {
    margin: 3px 0;
    font-size: 14px;
    line-height: 20px;
    height: 40px;
  }
  .pictrue-7-list .box_a .textbox .more span {
    width: 60px;
    font-size: 14px;
    line-height: 24px;
  }
  footer .box .left {
    float: inherit !important;
    padding: 0 0 20px;
    width: inherit;
    text-align: center;
  }
  footer .box .left:after {
    top: inherit;
    bottom: 0;
    width: 100%;
    height: 1px;
  }
  footer .box .left .tops {
    display: inline-block;
    vertical-align: top;
  }
  footer .box .left .textbox .tel {
    display: inline-block;
    vertical-align: top;
  }
  footer .box .left .textbox p br {
    display: none;
  }
  footer .box .right {
    float: inherit !important;
    width: inherit;
  }
  .subnav {
    margin: 0 0 20px;
  }
  .subnav .pcdnav {
    margin: 0;
  }
  .subnav ol h1 {
    position: relative;
    padding: 0 15px;
    width: 100%;
  }
  .subnav ol h1.active:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
  }
  .subnav ol h1 span {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .subnav-list.col-md-3 {
    width: 100%;
  }
  .subnav-list.col-md-9 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .main-pad-1 {
    padding: 20px 0 0;
  }
  .main-pad-2 {
    padding: 20px 0;
  }
  body {
    padding-bottom: 50px;
  }
  header .logo {
    line-height: 60px;
  }
  header .logo img {
    width: 220px;
  }
  header .menu {
    width: 120px;
  }
  header .menu ul li a {
    font-size: 14px;
    line-height: 34px;
  }
  header .ydd_btn {
    margin: 10px 0 0;
    padding: 10px;
  }
  .hot {
    height: 50px;
    background: #b18d5b;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    border-top: solid 2px #fff;
  }
  .hot span {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
  }
  .hot h6 {
    font-size: 12px;
    line-height: 20px;
  }
  .hot .row div a {
    display: block;
    color: #fff;
    padding: 7px 0;
  }
  .dbbox {
    position: fixed;
    left: 0;
    bottom: 70px;
    z-index: 99;
    width: 100%;
  }
  .dbbox .dbs {
    position: relative;
    padding: 0 15px;
    display: none;
  }
  .dbbox .dbs .neirong {
    padding: 10px;
    background: #fff;
    border-radius: 5px;
  }
  .dbbox .dbs.ewm-box {
    text-align: center;
  }
  .dbbox .dbs.ewm-box img {
    max-width: 140px;
  }
  .dbbox .dbs.fenxiang-box .bdshare-button-style1-32 .li {
    position: relative;
    padding: 6px 0;
    width: 20%;
    height: 44px;
  }
  .dbbox .dbs.fenxiang-box .bdshare-button-style1-32 a {
    position: absolute;
    left: 50%;
    top: 6px;
    margin: 0 0 0 -16px;
    float: inherit !important;
    display: block;
  }
  footer .footer_bar p {
    font-size: 14px;
  }
  footer .box .left .tops .logo {
    padding: 0;
    line-height: inherit;
  }
  .banner #myCarousel .carousel-indicators {
    bottom: 5px;
  }
  .banner #myCarousel .carousel-indicators li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
  }
  .text-1 .box {
    padding: 0;
  }
  .text-1 .box .tel {
    position: inherit;
    left: inherit;
    top: inherit;
    margin: 0 0 10px;
    padding: 0 0 0 24px;
    width: inherit;
    font-size: 16px;
    color: #666;
    background-size: 18px auto !important;
  }
  .text-1 .box .search {
    position: inherit;
    right: inherit;
    top: inherit;
    width: inherit;
  }
  .text-2 h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .text-2 p {
    font-size: 14px;
  }
  .text-3 .biaoti {
    font-size: 16px;
    line-height: 24px;
  }
  .text-3 p {
    font-size: 14px;
    line-height: 24px;
  }
  .text-4 ul li a {
    font-size: 14px;
  }
  .text-5 {
    font-size: 14px;
    line-height: 24px;
  }
  .title-1 a small {
    font-size: 16px;
    line-height: 24px;
  }
  .title-1 a h5 {
    line-height: 18px;
  }
  .title-1 a h5 span {
    font-size: 16px;
  }
  .title-1 a h5 span::after {
    left: -110px;
  }
  .title-1 a h5 span::before {
    right: -110px;
  }
  .title-2 ul li a {
    padding: 0 5px;
    font-size: 14px;
    min-width: 80px;
  }
  .title-3 .left a {
    font-size: 14px;
  }
  .title-3 .right a {
    font-size: 14px;
  }
  .title-5 h5 {
    font-size: 16px;
    line-height: 20px;
  }
  .more-1 {
    margin: 0 0 20px;
  }
  .more-1 a {
    font-size: 14px;
    line-height: 30px;
    width: 120px;
    border-radius: 15px;
  }
  .pictrue-1-list a h5 {
    font-size: 14px;
    line-height: 30px;
  }
  .pictrue-3 {
    padding: 0 28px;
  }
  .pictrue-3 a .imgbox {
    height: 80px;
    line-height: 74px;
  }
  .pictrue-4 .box_a {
    padding: 0 0 0 120px;
    min-height: 100px;
  }
  .pictrue-4 .box_a .imgbox {
    width: 120px;
  }
  .pictrue-4 .box_a .imgbox img {
    height: 100px;
  }
  .pictrue-4 .box_a .textbox {
    padding: 0 0 0 10px;
  }
  .pictrue-4 .box_a .textbox .biaoti {
    padding: 0 70px 0 0;
    line-height: 24px;
    min-height: 24px;
  }
  .pictrue-4 .box_a .textbox .biaoti h5 {
    font-size: 14px;
    line-height: 24px;
  }
  .pictrue-4 .box_a .textbox p {
    margin: 0 0 5px;
    height: 48px;
    line-height: 24px;
  }
  .pictrue-4 .box_a .textbox .more {
    line-height: 22px;
  }
}
@media (max-width: 479px) {
  footer .box .left .tops {
    display: block;
  }
  footer .box .left .tops .logo {
    float: inherit !important;
    margin: 0 0 10px;
    width: inherit;
  }
  footer .box .left .tops .ewm {
    float: inherit !important;
    padding: 0;
    width: inherit;
  }
  footer .box .left .tops .ewm h5 {
    position: inherit;
    right: inherit;
    top: inherit;
    width: inherit;
  }
  footer .box .left .textbox .tel {
    padding: 0 0 0 25px;
    font-size: 18px;
    line-height: 20px;
    background-size: auto 20px !important;
  }
  footer .box .left .textbox p {
    font-size: 14px;
    line-height: 24px;
  }
  footer .box .right form .hang {
    padding: 0 0 0 48px;
  }
  footer .box .right form .hang span {
    font-size: 14px;
  }
  footer .box .right form .hang input {
    font-size: 14px;
    width: 100% !important;
  }
  footer .box .right form .hang textarea {
    font-size: 14px;
  }
  footer .box .right form .hang .hangs .yzm {
    margin: 5px 0 0;
  }
  footer .box .right form .hang button {
    width: 100%;
  }
}

